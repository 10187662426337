import { GetServerSideProps } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { DOMAIN } from "./_app";

export default function Home() {
  const router = useRouter();

  useEffect(() => {
    router.push("/onboard");
  }, []);

  return <div></div>;
}

interface ChoicePageProps {
  ogImageUrl?: string;
}

export const getServerSideProps: GetServerSideProps<ChoicePageProps> = async ({
  query,
}) => {
  const { imageUrl, name } = query;

  if (!imageUrl || !name) {
    return {
      props: {
        imageUrlParam: "",
        nameParam: "",
      },
    };
  }
  const ogImageUrl = `${DOMAIN}/api/og/${encodeURIComponent(imageUrl as string)}/${encodeURIComponent(name as string)}`;

  return {
    props: {
      ogImageUrl,
    },
  };
};
